/* tslint:disable */
/* eslint-disable */
/**
 * Etana Digital API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface BlockdaemonGetStreamOfRewardsResponse
 */
export interface BlockdaemonGetStreamOfRewardsResponse {
    /**
     * Address which the rewards are for.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'address'?: string;
    /**
     * Denomination in which the reward is paid.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'denomination'?: string;
    /**
     * EndTime of when to look for rewards - in epoch seconds.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'endTime'?: string;
    /**
     * 
     * @type {BlockdaemonGetStreamOfRewardsResponseMetaData}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'metaData'?: BlockdaemonGetStreamOfRewardsResponseMetaData;
    /**
     * Period is how the data is aggregated. Default monthly.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'period'?: BlockdaemonGetStreamOfRewardsResponsePeriodEnum;
    /**
     * Return is the staking return of the address for the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'return'?: string;
    /**
     * StartBalance is the balance or stake at the start of the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'startBalance'?: string;
    /**
     * StartTime of when to look for rewards - in epoch seconds.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponse
     */
    'startTime'?: string;
}

export const BlockdaemonGetStreamOfRewardsResponsePeriodEnum = {
    Yearly: 'yearly',
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Epoch: 'epoch',
    Raw: 'raw'
} as const;

export type BlockdaemonGetStreamOfRewardsResponsePeriodEnum = typeof BlockdaemonGetStreamOfRewardsResponsePeriodEnum[keyof typeof BlockdaemonGetStreamOfRewardsResponsePeriodEnum];

/**
 * 
 * @export
 * @interface BlockdaemonGetStreamOfRewardsResponseMetaData
 */
export interface BlockdaemonGetStreamOfRewardsResponseMetaData {
    /**
     * AttestationReward is the attestation rewards in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'attestationReward'?: string;
    /**
     * BlockFeeReward is the block fee rewards in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'blockFeeReward'?: string;
    /**
     * BlockReward is the block reward in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'blockReward'?: string;
    /**
     * Deposits in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'deposits'?: string;
    /**
     * Epoch is/are the number/s that the period covers.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'epoch'?: string;
    /**
     * Index is the validator index.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'index'?: string;
    /**
     * MevBlockReward is the mev block rewards in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'mevBlockReward'?: string;
    /**
     * SyncCommitteeReward is the sync committee rewards in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'syncCommitteeReward'?: string;
    /**
     * Withdrawals in the period.
     * @type {string}
     * @memberof BlockdaemonGetStreamOfRewardsResponseMetaData
     */
    'withdrawals'?: string;
}
/**
 * 
 * @export
 * @interface DatabaseApproval
 */
export interface DatabaseApproval {
    /**
     * 
     * @type {string}
     * @memberof DatabaseApproval
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseApproval
     */
    'status'?: DatabaseApprovalStatusEnum;
}

export const DatabaseApprovalStatusEnum = {
    Pending: 'pending',
    Rejected: 'rejected',
    Approved: 'approved',
    Cancelled: 'cancelled'
} as const;

export type DatabaseApprovalStatusEnum = typeof DatabaseApprovalStatusEnum[keyof typeof DatabaseApprovalStatusEnum];

/**
 * 
 * @export
 * @interface DatabaseDeposit
 */
export interface DatabaseDeposit {
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'asset': string;
    /**
     * 
     * @type {number}
     * @memberof DatabaseDeposit
     */
    'blockNumber': number;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'destinationAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseDeposit
     */
    'destinationAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'originAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseDeposit
     */
    'originAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'seenAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseDeposit
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'txHash': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface DatabaseStake
 */
export interface DatabaseStake {
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'amountStakeUnits': string;
    /**
     * 
     * @type {DatabaseApproval}
     * @memberof DatabaseStake
     */
    'approval': DatabaseApproval;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'blockdaemonStakeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'contractAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'requestingManager': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'rewardRecipient'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'stakeUnits': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'txHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'unsignedTransaction': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseStake
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface DatabaseTransaction
 */
export interface DatabaseTransaction {
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'amountV2': string;
    /**
     * 
     * @type {DatabaseApproval}
     * @memberof DatabaseTransaction
     */
    'approval': DatabaseApproval;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'dateProcessed'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'dateVerified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'destinationAddress': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'direction': DatabaseTransactionDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'originAddress': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'requestingManager': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'txHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'txId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'txProcessingStatus': DatabaseTransactionTxProcessingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'walletId': string;
}

export const DatabaseTransactionDirectionEnum = {
    Withdraw: 'withdraw'
} as const;

export type DatabaseTransactionDirectionEnum = typeof DatabaseTransactionDirectionEnum[keyof typeof DatabaseTransactionDirectionEnum];
export const DatabaseTransactionTxProcessingStatusEnum = {
    Pending: 'pending',
    Cancelled: 'cancelled',
    Processing: 'processing',
    Submitted: 'submitted',
    Success: 'success',
    Failure: 'failure'
} as const;

export type DatabaseTransactionTxProcessingStatusEnum = typeof DatabaseTransactionTxProcessingStatusEnum[keyof typeof DatabaseTransactionTxProcessingStatusEnum];

/**
 * 
 * @export
 * @interface DatabaseUser
 */
export interface DatabaseUser {
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'givenName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseUser
     */
    'groups': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'sub': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'webhookKey': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'webhookUrl'?: string;
}
/**
 * 
 * @export
 * @interface DatabaseWallet
 */
export interface DatabaseWallet {
    /**
     * 
     * @type {{ [key: string]: OpenapiAccount; }}
     * @memberof DatabaseWallet
     */
    'accounts'?: { [key: string]: OpenapiAccount; };
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseWallet
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseWallet
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface HandlePostStakeIntentRequest
 */
export interface HandlePostStakeIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlePostStakeIntentRequest
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof HandlePostStakeIntentRequest
     */
    'network': HandlePostStakeIntentRequestNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof HandlePostStakeIntentRequest
     */
    'rewardRecipient'?: string;
    /**
     * 
     * @type {string}
     * @memberof HandlePostStakeIntentRequest
     */
    'walletId': string;
}

export const HandlePostStakeIntentRequestNetworkEnum = {
    Ethereum: 'ethereum',
    Avalanche: 'avalanche',
    Solana: 'solana'
} as const;

export type HandlePostStakeIntentRequestNetworkEnum = typeof HandlePostStakeIntentRequestNetworkEnum[keyof typeof HandlePostStakeIntentRequestNetworkEnum];

/**
 * 
 * @export
 * @interface HandleUpdatedUser
 */
export interface HandleUpdatedUser {
    /**
     * 
     * @type {DatabaseUser}
     * @memberof HandleUpdatedUser
     */
    'data'?: DatabaseUser;
}
/**
 * 
 * @export
 * @interface OpenapiAccount
 */
export interface OpenapiAccount {
    /**
     * 
     * @type {boolean}
     * @memberof OpenapiAccount
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'address'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenapiAccount
     */
    'assets'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'network'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiBlockchainAssetsData
 */
export interface OpenapiBlockchainAssetsData {
    /**
     * 
     * @type {Array<OpenapiBlockchainAssetsNetworkData>}
     * @memberof OpenapiBlockchainAssetsData
     */
    'data'?: Array<OpenapiBlockchainAssetsNetworkData>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiBlockchainAssetsData
     */
    'network'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiBlockchainAssetsNetworkData
 */
export interface OpenapiBlockchainAssetsNetworkData {
    /**
     * 
     * @type {string}
     * @memberof OpenapiBlockchainAssetsNetworkData
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiBlockchainAssetsNetworkData
     */
    'contractAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenapiBlockchainAssetsNetworkData
     */
    'decimals'?: number;
}
/**
 * 
 * @export
 * @interface OpenapiOpenapiWatchtowerCoreBalanceResponse
 */
export interface OpenapiOpenapiWatchtowerCoreBalanceResponse {
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'balance'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'decimals'?: number;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'network'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiRoutesBalanceOnWalletResponse
 */
export interface OpenapiRoutesBalanceOnWalletResponse {
    /**
     * 
     * @type {Array<OpenapiOpenapiWatchtowerCoreBalanceResponse>}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'data'?: Array<OpenapiOpenapiWatchtowerCoreBalanceResponse>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'walletId'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiWalletApiATransactionSubmitResponse
 */
export interface OpenapiWalletApiATransactionSubmitResponse {
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'network'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'txHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'walletId'?: string;
}
/**
 * 
 * @export
 * @interface RequestAddAssetRequest
 */
export interface RequestAddAssetRequest {
    /**
     * 
     * @type {Array<RequestAssetData>}
     * @memberof RequestAddAssetRequest
     */
    'assets': Array<RequestAssetData>;
    /**
     * 
     * @type {string}
     * @memberof RequestAddAssetRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestAddOrRemoveManagersRequest
 */
export interface RequestAddOrRemoveManagersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestAddOrRemoveManagersRequest
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestAddOrRemoveManagersRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestAssetData
 */
export interface RequestAssetData {
    /**
     * 
     * @type {string}
     * @memberof RequestAssetData
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAssetData
     */
    'network': string;
}
/**
 * 
 * @export
 * @interface RequestCreateTransactionRequest
 */
export interface RequestCreateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'destinationAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestCreateWallet
 */
export interface RequestCreateWallet {
    /**
     * 
     * @type {Array<RequestAssetData>}
     * @memberof RequestCreateWallet
     */
    'assets'?: Array<RequestAssetData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestCreateWallet
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateWallet
     */
    'orgId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateWallet
     */
    'owner': string;
}
/**
 * 
 * @export
 * @interface RequestHideAssetRequest
 */
export interface RequestHideAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestUserUpdate
 */
export interface RequestUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdate
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdate
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdate
     */
    'webhookUrl'?: string;
}
/**
 * 
 * @export
 * @interface RequestUserUpdateRequest
 */
export interface RequestUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdateRequest
     */
    'email': string;
    /**
     * 
     * @type {RequestUserUpdate}
     * @memberof RequestUserUpdateRequest
     */
    'update'?: RequestUserUpdate;
}

/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsAssetsAddPost: async (req: RequestAddAssetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('walletsAssetsAddPost', 'req', req)
            const localVarPath = `/wallets/assets/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get available assets
         * @summary Get assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsAssetsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wallets/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsAssetsHidePost: async (req: RequestHideAssetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('walletsAssetsHidePost', 'req', req)
            const localVarPath = `/wallets/assets/hide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsAssetsAddPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.walletsAssetsAddPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get available assets
         * @summary Get assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsAssetsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpenapiBlockchainAssetsData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsAssetsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.walletsAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsAssetsHidePost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.walletsAssetsHidePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsAssetsAddPost(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Get available assets
         * @summary Get assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsAssetsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OpenapiBlockchainAssetsData>> {
            return localVarFp.walletsAssetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsAssetsHidePost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * Add an asset to a wallet
     * @summary Add Asset
     * @param {RequestAddAssetRequest} req add asset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public walletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).walletsAssetsAddPost(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get available assets
     * @summary Get assets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public walletsAssetsGet(options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).walletsAssetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hide an asset from a wallet
     * @summary Hide Asset
     * @param {RequestHideAssetRequest} req hide asset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public walletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).walletsAssetsHidePost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsBalancesIdPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsBalancesIdPost', 'id', id)
            const localVarPath = `/wallets/balances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsBalancesIdPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenapiRoutesBalanceOnWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsBalancesIdPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.walletsBalancesIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceApiFp(configuration)
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsBalancesIdPost(id: string, options?: RawAxiosRequestConfig): AxiosPromise<OpenapiRoutesBalanceOnWalletResponse> {
            return localVarFp.walletsBalancesIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * Retrieve the balance based on wallet ID.
     * @summary Get Balance
     * @param {string} id Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public walletsBalancesIdPost(id: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).walletsBalancesIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepositApi - axios parameter creator
 * @export
 */
export const DepositApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all deposits given the user role
         * @summary Get deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deposits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositApi - functional programming interface
 * @export
 */
export const DepositApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all deposits given the user role
         * @summary Get deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async depositsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseDeposit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.depositsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositApi.depositsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepositApi - factory interface
 * @export
 */
export const DepositApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositApiFp(configuration)
    return {
        /**
         * Get all deposits given the user role
         * @summary Get deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseDeposit>> {
            return localVarFp.depositsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositApi - object-oriented interface
 * @export
 * @class DepositApi
 * @extends {BaseAPI}
 */
export class DepositApi extends BaseAPI {
    /**
     * Get all deposits given the user role
     * @summary Get deposits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositApi
     */
    public depositsGet(options?: RawAxiosRequestConfig) {
        return DepositApiFp(this.configuration).depositsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManagerApi - axios parameter creator
 * @export
 */
export const ManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete managers from a wallet
         * @summary Delete Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsManagersDelete: async (req: RequestAddOrRemoveManagersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('walletsManagersDelete', 'req', req)
            const localVarPath = `/wallets/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add managers to a wallet
         * @summary Add Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsManagersPost: async (req: RequestAddOrRemoveManagersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('walletsManagersPost', 'req', req)
            const localVarPath = `/wallets/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagerApi - functional programming interface
 * @export
 */
export const ManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete managers from a wallet
         * @summary Delete Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsManagersDelete(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsManagersDelete(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagerApi.walletsManagersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add managers to a wallet
         * @summary Add Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsManagersPost(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsManagersPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagerApi.walletsManagersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ManagerApi - factory interface
 * @export
 */
export const ManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagerApiFp(configuration)
    return {
        /**
         * Delete managers from a wallet
         * @summary Delete Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsManagersDelete(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsManagersDelete(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Add managers to a wallet
         * @summary Add Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsManagersPost(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsManagersPost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagerApi - object-oriented interface
 * @export
 * @class ManagerApi
 * @extends {BaseAPI}
 */
export class ManagerApi extends BaseAPI {
    /**
     * Delete managers from a wallet
     * @summary Delete Managers
     * @param {RequestAddOrRemoveManagersRequest} req add managers request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerApi
     */
    public walletsManagersDelete(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig) {
        return ManagerApiFp(this.configuration).walletsManagersDelete(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add managers to a wallet
     * @summary Add Managers
     * @param {RequestAddOrRemoveManagersRequest} req add managers request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerApi
     */
    public walletsManagersPost(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig) {
        return ManagerApiFp(this.configuration).walletsManagersPost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StakeApi - axios parameter creator
 * @export
 */
export const StakeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get stakes
         * @summary Get Stakes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/staking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve or reject stake
         * @summary Stake action
         * @param {string} stakeId Stake ID
         * @param {string} action action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingIdStakeIdActionPost: async (stakeId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stakeId' is not null or undefined
            assertParamExists('stakingIdStakeIdActionPost', 'stakeId', stakeId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('stakingIdStakeIdActionPost', 'action', action)
            const localVarPath = `/staking/id/{stakeId}/{action}`
                .replace(`{${"stakeId"}}`, encodeURIComponent(String(stakeId)))
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get stake
         * @summary Get Stake
         * @param {string} stakeId Stake ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingIdStakeIdGet: async (stakeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stakeId' is not null or undefined
            assertParamExists('stakingIdStakeIdGet', 'stakeId', stakeId)
            const localVarPath = `/staking/id/{stakeId}`
                .replace(`{${"stakeId"}}`, encodeURIComponent(String(stakeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve or reject stake
         * @summary Stake action
         * @param {HandlePostStakeIntentRequest} req post stake intent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingPost: async (req: HandlePostStakeIntentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('stakingPost', 'req', req)
            const localVarPath = `/staking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get staking rewards by address
         * @summary Staking Rewards
         * @param {string} protocol protocol
         * @param {string} address address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingRewardsProtocolAddressAddressGet: async (protocol: string, address: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'protocol' is not null or undefined
            assertParamExists('stakingRewardsProtocolAddressAddressGet', 'protocol', protocol)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('stakingRewardsProtocolAddressAddressGet', 'address', address)
            const localVarPath = `/staking/rewards/{protocol}/address/{address}`
                .replace(`{${"protocol"}}`, encodeURIComponent(String(protocol)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get staking rewards
         * @summary Staking Rewards
         * @param {string} protocol protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingRewardsProtocolGet: async (protocol: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'protocol' is not null or undefined
            assertParamExists('stakingRewardsProtocolGet', 'protocol', protocol)
            const localVarPath = `/staking/rewards/{protocol}`
                .replace(`{${"protocol"}}`, encodeURIComponent(String(protocol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StakeApi - functional programming interface
 * @export
 */
export const StakeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StakeApiAxiosParamCreator(configuration)
    return {
        /**
         * Get stakes
         * @summary Get Stakes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseStake>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StakeApi.stakingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Approve or reject stake
         * @summary Stake action
         * @param {string} stakeId Stake ID
         * @param {string} action action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakingIdStakeIdActionPost(stakeId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseStake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakingIdStakeIdActionPost(stakeId, action, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StakeApi.stakingIdStakeIdActionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get stake
         * @summary Get Stake
         * @param {string} stakeId Stake ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakingIdStakeIdGet(stakeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseStake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakingIdStakeIdGet(stakeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StakeApi.stakingIdStakeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Approve or reject stake
         * @summary Stake action
         * @param {HandlePostStakeIntentRequest} req post stake intent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakingPost(req: HandlePostStakeIntentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseStake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakingPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StakeApi.stakingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get staking rewards by address
         * @summary Staking Rewards
         * @param {string} protocol protocol
         * @param {string} address address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakingRewardsProtocolAddressAddressGet(protocol: string, address: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockdaemonGetStreamOfRewardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakingRewardsProtocolAddressAddressGet(protocol, address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StakeApi.stakingRewardsProtocolAddressAddressGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get staking rewards
         * @summary Staking Rewards
         * @param {string} protocol protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakingRewardsProtocolGet(protocol: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockdaemonGetStreamOfRewardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakingRewardsProtocolGet(protocol, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StakeApi.stakingRewardsProtocolGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StakeApi - factory interface
 * @export
 */
export const StakeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StakeApiFp(configuration)
    return {
        /**
         * Get stakes
         * @summary Get Stakes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseStake>> {
            return localVarFp.stakingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Approve or reject stake
         * @summary Stake action
         * @param {string} stakeId Stake ID
         * @param {string} action action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingIdStakeIdActionPost(stakeId: string, action: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseStake> {
            return localVarFp.stakingIdStakeIdActionPost(stakeId, action, options).then((request) => request(axios, basePath));
        },
        /**
         * Get stake
         * @summary Get Stake
         * @param {string} stakeId Stake ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingIdStakeIdGet(stakeId: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseStake> {
            return localVarFp.stakingIdStakeIdGet(stakeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Approve or reject stake
         * @summary Stake action
         * @param {HandlePostStakeIntentRequest} req post stake intent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingPost(req: HandlePostStakeIntentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseStake> {
            return localVarFp.stakingPost(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Get staking rewards by address
         * @summary Staking Rewards
         * @param {string} protocol protocol
         * @param {string} address address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingRewardsProtocolAddressAddressGet(protocol: string, address: string, options?: RawAxiosRequestConfig): AxiosPromise<BlockdaemonGetStreamOfRewardsResponse> {
            return localVarFp.stakingRewardsProtocolAddressAddressGet(protocol, address, options).then((request) => request(axios, basePath));
        },
        /**
         * Get staking rewards
         * @summary Staking Rewards
         * @param {string} protocol protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakingRewardsProtocolGet(protocol: string, options?: RawAxiosRequestConfig): AxiosPromise<BlockdaemonGetStreamOfRewardsResponse> {
            return localVarFp.stakingRewardsProtocolGet(protocol, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StakeApi - object-oriented interface
 * @export
 * @class StakeApi
 * @extends {BaseAPI}
 */
export class StakeApi extends BaseAPI {
    /**
     * Get stakes
     * @summary Get Stakes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeApi
     */
    public stakingGet(options?: RawAxiosRequestConfig) {
        return StakeApiFp(this.configuration).stakingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Approve or reject stake
     * @summary Stake action
     * @param {string} stakeId Stake ID
     * @param {string} action action to be performed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeApi
     */
    public stakingIdStakeIdActionPost(stakeId: string, action: string, options?: RawAxiosRequestConfig) {
        return StakeApiFp(this.configuration).stakingIdStakeIdActionPost(stakeId, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get stake
     * @summary Get Stake
     * @param {string} stakeId Stake ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeApi
     */
    public stakingIdStakeIdGet(stakeId: string, options?: RawAxiosRequestConfig) {
        return StakeApiFp(this.configuration).stakingIdStakeIdGet(stakeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Approve or reject stake
     * @summary Stake action
     * @param {HandlePostStakeIntentRequest} req post stake intent request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeApi
     */
    public stakingPost(req: HandlePostStakeIntentRequest, options?: RawAxiosRequestConfig) {
        return StakeApiFp(this.configuration).stakingPost(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get staking rewards by address
     * @summary Staking Rewards
     * @param {string} protocol protocol
     * @param {string} address address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeApi
     */
    public stakingRewardsProtocolAddressAddressGet(protocol: string, address: string, options?: RawAxiosRequestConfig) {
        return StakeApiFp(this.configuration).stakingRewardsProtocolAddressAddressGet(protocol, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get staking rewards
     * @summary Staking Rewards
     * @param {string} protocol protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeApi
     */
    public stakingRewardsProtocolGet(protocol: string, options?: RawAxiosRequestConfig) {
        return StakeApiFp(this.configuration).stakingRewardsProtocolGet(protocol, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approve a transaction
         * @summary Approve Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsApproveTxIdPost: async (txId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('transactionsApproveTxIdPost', 'txId', txId)
            const localVarPath = `/transactions/approve/{txId}`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new transaction
         * @summary Create Transaction
         * @param {RequestCreateTransactionRequest} req create transaction request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsCreatePost: async (req: RequestCreateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('transactionsCreatePost', 'req', req)
            const localVarPath = `/transactions/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all withdrawals given the user role
         * @summary Get withdrawals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reject a transaction
         * @summary Reject Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsRejectTxIdPost: async (txId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('transactionsRejectTxIdPost', 'txId', txId)
            const localVarPath = `/transactions/reject/{txId}`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a transaction status
         * @summary Get status
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsStatusTxIdGet: async (txId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('transactionsStatusTxIdGet', 'txId', txId)
            const localVarPath = `/transactions/status/{txId}`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * Approve a transaction
         * @summary Approve Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsApproveTxIdPost(txId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenapiWalletApiATransactionSubmitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsApproveTxIdPost(txId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.transactionsApproveTxIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new transaction
         * @summary Create Transaction
         * @param {RequestCreateTransactionRequest} req create transaction request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsCreatePost(req: RequestCreateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsCreatePost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.transactionsCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all withdrawals given the user role
         * @summary Get withdrawals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseTransaction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.transactionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reject a transaction
         * @summary Reject Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsRejectTxIdPost(txId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsRejectTxIdPost(txId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.transactionsRejectTxIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a transaction status
         * @summary Get status
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsStatusTxIdGet(txId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsStatusTxIdGet(txId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.transactionsStatusTxIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * Approve a transaction
         * @summary Approve Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsApproveTxIdPost(txId: string, options?: RawAxiosRequestConfig): AxiosPromise<OpenapiWalletApiATransactionSubmitResponse> {
            return localVarFp.transactionsApproveTxIdPost(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new transaction
         * @summary Create Transaction
         * @param {RequestCreateTransactionRequest} req create transaction request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsCreatePost(req: RequestCreateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseTransaction> {
            return localVarFp.transactionsCreatePost(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all withdrawals given the user role
         * @summary Get withdrawals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseTransaction>> {
            return localVarFp.transactionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Reject a transaction
         * @summary Reject Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsRejectTxIdPost(txId: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseTransaction> {
            return localVarFp.transactionsRejectTxIdPost(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a transaction status
         * @summary Get status
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsStatusTxIdGet(txId: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseTransaction> {
            return localVarFp.transactionsStatusTxIdGet(txId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * Approve a transaction
     * @summary Approve Transaction
     * @param {string} txId Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsApproveTxIdPost(txId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsApproveTxIdPost(txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new transaction
     * @summary Create Transaction
     * @param {RequestCreateTransactionRequest} req create transaction request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsCreatePost(req: RequestCreateTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsCreatePost(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all withdrawals given the user role
     * @summary Get withdrawals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsGet(options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reject a transaction
     * @summary Reject Transaction
     * @param {string} txId Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsRejectTxIdPost(txId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsRejectTxIdPost(txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a transaction status
     * @summary Get status
     * @param {string} txId Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsStatusTxIdGet(txId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsStatusTxIdGet(txId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * issues, rotates, or optionally deletes a user\'s API key
         * @summary Manage user api jey
         * @param {string} [_delete] true if this is a delete request
         * @param {string} [email] optional email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userApikeyPatch: async (_delete?: string, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/apikey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_delete !== undefined) {
                localVarQueryParameter['delete'] = _delete;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns current logged user
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCurrentGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users given the user role
         * @summary Get users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user
         * @summary Update user
         * @param {RequestUserUpdateRequest} req update user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatch: async (req: RequestUserUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('userPatch', 'req', req)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * issues, rotates, or optionally deletes a user\'s API key
         * @summary Manage user api jey
         * @param {string} [_delete] true if this is a delete request
         * @param {string} [email] optional email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userApikeyPatch(_delete?: string, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandleUpdatedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userApikeyPatch(_delete, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userApikeyPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns current logged user
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCurrentGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCurrentGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userCurrentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all users given the user role
         * @summary Get users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a user
         * @summary Update user
         * @param {RequestUserUpdateRequest} req update user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPatch(req: RequestUserUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPatch(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * issues, rotates, or optionally deletes a user\'s API key
         * @summary Manage user api jey
         * @param {string} [_delete] true if this is a delete request
         * @param {string} [email] optional email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userApikeyPatch(_delete?: string, email?: string, options?: RawAxiosRequestConfig): AxiosPromise<HandleUpdatedUser> {
            return localVarFp.userApikeyPatch(_delete, email, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns current logged user
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCurrentGet(options?: RawAxiosRequestConfig): AxiosPromise<DatabaseUser> {
            return localVarFp.userCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users given the user role
         * @summary Get users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseUser>> {
            return localVarFp.userGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user
         * @summary Update user
         * @param {RequestUserUpdateRequest} req update user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatch(req: RequestUserUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseUser> {
            return localVarFp.userPatch(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * issues, rotates, or optionally deletes a user\'s API key
     * @summary Manage user api jey
     * @param {string} [_delete] true if this is a delete request
     * @param {string} [email] optional email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userApikeyPatch(_delete?: string, email?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userApikeyPatch(_delete, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns current logged user
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCurrentGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users given the user role
     * @summary Get users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user
     * @summary Update user
     * @param {RequestUserUpdateRequest} req update user request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPatch(req: RequestUserUpdateRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userPatch(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable a wallet
         * @summary Enable Wallet
         * @param {string} walletId Wallet ID
         * @param {string} action Action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWalletsWalletIdActionPost: async (walletId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('adminWalletsWalletIdActionPost', 'walletId', walletId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('adminWalletsWalletIdActionPost', 'action', action)
            const localVarPath = `/admin/wallets/{walletId}/{action}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a wallets automatically filterd by user role
         * @summary Get Wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get wallet field
         * @summary Get wallet field
         * @param {string} id Wallet ID
         * @param {string} field Field to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsIdIdFieldGet: async (id: string, field: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsIdIdFieldGet', 'id', id)
            // verify required parameter 'field' is not null or undefined
            assertParamExists('walletsIdIdFieldGet', 'field', field)
            const localVarPath = `/wallets/id/{id}/{field}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an wallet by id
         * @summary Get Wallet
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsIdIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsIdIdGet', 'id', id)
            const localVarPath = `/wallets/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new wallet
         * @summary Create Wallet
         * @param {RequestCreateWallet} req create wallet request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsPost: async (req: RequestCreateWallet, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('walletsPost', 'req', req)
            const localVarPath = `/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable a wallet
         * @summary Enable Wallet
         * @param {string} walletId Wallet ID
         * @param {string} action Action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminWalletsWalletIdActionPost(walletId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminWalletsWalletIdActionPost(walletId, action, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.adminWalletsWalletIdActionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a wallets automatically filterd by user role
         * @summary Get Wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseWallet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.walletsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get wallet field
         * @summary Get wallet field
         * @param {string} id Wallet ID
         * @param {string} field Field to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsIdIdFieldGet(id: string, field: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsIdIdFieldGet(id, field, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.walletsIdIdFieldGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an wallet by id
         * @summary Get Wallet
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsIdIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsIdIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.walletsIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new wallet
         * @summary Create Wallet
         * @param {RequestCreateWallet} req create wallet request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsPost(req: RequestCreateWallet, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.walletsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * Enable a wallet
         * @summary Enable Wallet
         * @param {string} walletId Wallet ID
         * @param {string} action Action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWalletsWalletIdActionPost(walletId: string, action: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.adminWalletsWalletIdActionPost(walletId, action, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a wallets automatically filterd by user role
         * @summary Get Wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseWallet>> {
            return localVarFp.walletsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get wallet field
         * @summary Get wallet field
         * @param {string} id Wallet ID
         * @param {string} field Field to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsIdIdFieldGet(id: string, field: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsIdIdFieldGet(id, field, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an wallet by id
         * @summary Get Wallet
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsIdIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsIdIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new wallet
         * @summary Create Wallet
         * @param {RequestCreateWallet} req create wallet request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsPost(req: RequestCreateWallet, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.walletsPost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * Enable a wallet
     * @summary Enable Wallet
     * @param {string} walletId Wallet ID
     * @param {string} action Action to be performed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public adminWalletsWalletIdActionPost(walletId: string, action: string, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).adminWalletsWalletIdActionPost(walletId, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a wallets automatically filterd by user role
     * @summary Get Wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public walletsGet(options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).walletsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get wallet field
     * @summary Get wallet field
     * @param {string} id Wallet ID
     * @param {string} field Field to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public walletsIdIdFieldGet(id: string, field: string, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).walletsIdIdFieldGet(id, field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an wallet by id
     * @summary Get Wallet
     * @param {string} id Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public walletsIdIdGet(id: string, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).walletsIdIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new wallet
     * @summary Create Wallet
     * @param {RequestCreateWallet} req create wallet request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public walletsPost(req: RequestCreateWallet, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).walletsPost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



