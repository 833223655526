import Cookies from 'universal-cookie';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, type RawAxiosRequestConfig } from 'axios';

import { urls } from '../config';
import { Configuration } from '../sdk';

export const config = new Configuration({
  basePath: urls.api,
});

export const getBaseOptions = (): RawAxiosRequestConfig => {
  const cookies = new Cookies();
  return {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('jwt_token')}`,
    },
  }
}

export const handleApiRequest = async <T>(request: () => Promise<AxiosResponse<T>>): Promise<T> => {
  try {
    const res = await request();
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw e.response.data;
    }
    throw e;
  }
}

async function axiosRequest(
  url: string,
  options: Partial<AxiosRequestConfig> | null = null,
): Promise<AxiosResponse<any, any>> {
  const cookies = new Cookies();
  options ||= {};
  options.method ||= 'GET';
  options.timeout ||= 30000;
  options.baseURL = urls.api;
  options.withCredentials = true;
  options.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.get('jwt_token')}`,
  };

  return axios.request({ url, ...options });
}

export async function httpGet(url: string) {
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) {
    throw new Error('No response');
  }
  if (res.status >= 300) {
    throw new Error(res.data || res.status);
  }
  return res.data;
}

export default axiosRequest;
