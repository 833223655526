import { baseCard } from '../../../../config/tailwind.classnames';
import { ChangeEventHandler, useEffect, useState } from 'react';
import axiosRequest from '../../../../store/axios';
import { urls } from '../../../../config';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import capitalize from '../../../../utils/capitalize';
import { toast } from 'react-hot-toast';

type KeyOfUnion<T> = T extends T ? keyof T : never;

type SingleNotificationSetting = {
  inactive: boolean;
  email: boolean;
  inApp: boolean;
  webhook: boolean;
};

type UserSettings = {
  inactive: boolean;
  withdrawal: {
    inactive: boolean;
    created: SingleNotificationSetting;
    approved: SingleNotificationSetting;
    confirmed: SingleNotificationSetting;
  };
  deposit: {
    inactive: boolean;
    created: SingleNotificationSetting;
  };
  account: {
    inactive: boolean;
    signIn: SingleNotificationSetting;
  };
};

type Section = keyof UserSettings;

type SubSection = KeyOfUnion<
  UserSettings['withdrawal'] | UserSettings['deposit'] | UserSettings['account']
>;

export function NotificationSettings() {
  const [settings, setSettings] = useState<UserSettings | null>(null);

  useEffect(() => {
    axiosRequest(`${urls.notifications}/settings`, { method: 'GET' })
      .then(res => setSettings(res.data.data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (!settings) {
      return;
    }
    axiosRequest(`${urls.notifications}/settings`, { method: 'PATCH', data: settings }).catch(e =>
      toast.error(`Failed to update notification settings: ${e}`),
    );
  }, [settings]);

  if (!settings) {
    return (
      <div className={baseCard}>
        <div className="p-10 min-w-1xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className={`${baseCard} space-y-5`}>
      <h2 className="text-lg">Notification settings</h2>
      <ToggleWithLabel
        title="Receive Notifications"
        toggleName="all"
        active={!settings.inactive}
        onChange={() => setSettings(curr => ({ ...curr, inactive: !curr.inactive }))}
      />
      <SectionDropdown
        title="withdrawal"
        settings={settings}
        onToggleItem={(subSection, settingName) => {
          const withdrawal = settings.withdrawal;
          switch (subSection) {
            // Unreachable cases have to be included to guarantee type safety in the default case
            case 'signIn':
              return;
            case 'inactive':
              withdrawal.inactive = !withdrawal.inactive;
              break;
            default:
              withdrawal[subSection][settingName] = !withdrawal[subSection][settingName];
          }
          setSettings({ ...settings, withdrawal });
        }}
      />
      <SectionDropdown
        title="deposit"
        settings={settings}
        onToggleItem={(subSection, settingName) => {
          const deposit = settings.deposit;
          switch (subSection) {
            // Unreachable cases have to be included to guarantee type safety in the default case
            case 'approved':
            case 'confirmed':
            case 'signIn':
              return;
            case 'inactive':
              deposit.inactive = !deposit.inactive;
              break;
            default:
              deposit[subSection][settingName] = !deposit[subSection][settingName];
          }
          setSettings({ ...settings, deposit });
        }}
      />
      <SectionDropdown
        title="account"
        settings={settings}
        onToggleItem={(subSection, settingName) => {
          const account = settings.account;
          switch (subSection) {
            // Unreachable cases have to be included to guarantee type safety in the default case
            case 'approved':
            case 'created':
            case 'confirmed':
              return;
            case 'inactive':
              account.inactive = !account.inactive;
              break;
            default:
              account[subSection][settingName] = !account[subSection][settingName];
          }
          setSettings({ ...settings, account });
        }}
      />
    </div>
  );
}

function SectionDropdown({
  title,
  settings,
  onToggleItem,
}: {
  title: Section;
  settings: UserSettings;
  onToggleItem: (subSection: SubSection, settingName: keyof SingleNotificationSetting) => void;
}) {
  const [open, setOpen] = useState(false);

  let setting;
  switch (title) {
    case 'withdrawal':
      setting = settings.withdrawal;
      break;
    case 'deposit':
      setting = settings.deposit;
      break;
    case 'account':
      setting = settings.account;
      break;
  }

  return (
    <div className="space-y-2">
      <span className="flex space-x-2 items-center">
        <h3 className="capitalize indent-5">{title}</h3>
        <button
          className="border hover:border-white border-transparent rounded-md p-0.5"
          onClick={() => setOpen(curr => !curr)}
        >
          <ChevronRightIcon className={`transition-all w-5 ${open ? 'rotate-90' : ''}`} />
        </button>
      </span>
      <div className={`indent-10 space-y-2 ${!open ? 'hidden' : ''}`}>
        <ToggleWithLabel
          title={`${capitalize(title)} Notifications`}
          toggleName={title}
          active={!setting.inactive}
          onChange={() => onToggleItem('inactive', null)}
        />
        {Object.entries(setting ?? {}).map(([key, value]) => (
          <DropdownItems subSection={key} value={value} onToggleItem={onToggleItem} />
        ))}
      </div>
    </div>
  );
}

function DropdownItems({
  subSection,
  value,
  onToggleItem,
}: {
  subSection: string;
  value: boolean | SingleNotificationSetting;
  onToggleItem: (key: string, name: string) => void;
}) {
  if (typeof value === 'boolean') return null; // TODO: implement inactive at section level

  return (
    <div key={subSection}>
      <h2 className="capitalize">{subSection}</h2>
      <div className="indent-20 space-y-2">
        <ToggleWithLabel
          title="In App"
          toggleName="inApp"
          active={value?.inApp}
          onChange={evt => onToggleItem(subSection, evt.target.name)}
        />
        <ToggleWithLabel
          title="Email"
          toggleName="email"
          active={value?.email}
          onChange={evt => onToggleItem(subSection, evt.target.name)}
        />
        <ToggleWithLabel
          title="Webhooks"
          toggleName="webhook"
          active={value?.webhook}
          onChange={evt => onToggleItem(subSection, evt.target.name)}
        />
      </div>
    </div>
  );
}

function ToggleWithLabel({
  title,
  toggleName,
  active,
  onChange,
}: {
  title: string;
  toggleName: string;
  active?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <div className="flex items-center space-x-5">
      <span className="min-w-48">{title}</span>
      <ToggleSwitch name={toggleName} active={active} onChange={onChange} />
    </div>
  );
}

function ToggleSwitch({
  name,
  active,
  onChange,
}: {
  name: string;
  active?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        name={name}
        checked={active}
        className="sr-only peer"
        onChange={onChange}
      />
      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-blue-600"></div>
    </label>
  );
}
